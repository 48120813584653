 import "./Footer.css"
 import logo from "../Images/BeautySalonNews.png"
 import { Link } from "react-router-dom";
import { FaFacebook,FaInstagram } from "react-icons/fa";

 const Footer = () => {
  const currentYear = new Date().getFullYear()

   return <footer>
    <div className="footer">
      <div className="footer-places link">
          <Link to="/"><img src={logo} alt="logo"/></Link>
          <div className="navbar-text color">
            <h3>BEAUTY RYCHNOV</h3>
          </div>
      </div>
      <div className="footer-places footer-link">
        <Link to="/">Domů</Link>
        <Link to="cenik">Ceník</Link>
        <Link to="lrbeuty">LR</Link>
        <Link to="kontakt">Kontakt</Link>
      </div>
      <div className="footer-places">
        <div className="odkazy mensi">
                <Link to="https://facebook.com/profile.php?id=61559008991235&name=STSDirectNavUserDecorator"><FaFacebook /></Link>
                <Link to="https://www.instagram.com/hana.simkova.73/"><FaInstagram /></Link>
        </div>
              <h2>Hana Šimková</h2>
              <p>+420 737985366</p>
              <p>simkova-73@seznam.cz</p>
              <p>U Stadionu 1649</p> <p>Rychnov nad Kněžnou</p>
              
      </div>
    </div>
    <div className="footer-podpis">
      <p>© {currentYear} <Link to="https://www.webnexus.cz"><strong>Webnexus.cz</strong></Link>. Všechna práva vyhrazena.</p>
    </div>
   </footer>
 }
 
 export default Footer