
const InfoData = [
    {
        id: 1,
        title: "Zářivá a zdravá pleť",
        text: "S naším hloubkovým čištěním, peelingem, séry a krémy vám zajistíme dlouhodobě krásnou a svěží pleť, kterou si zamilujete!",

    },
    {
        id: 2,
        title: "Oáza relaxace",
        text: "Dopřejte si dokonalou masáž obličeje, která vás uvolní a zároveň zlepší stav vaší pleti. Odpočinek a krása jdou ruku v ruce.",

    },
    {
        id: 3,
        title: "Péče na míru vašim potřebám",
        text: "Ať už preferujete jednoduché ošetření, nebo exkluzivní balíček, naše služby jsou vždy přizpůsobeny vašim individuálním potřebám.",

    },
    {
        id: 4,
        title: "Šetrná depilace bez kompromisů",
        text: "S naší šetrnou metodou depilace se zbavíte nežádoucích chloupků rychle a bez bolesti.",

    },
    {
        id: 5,
        title: "Perfektně upravené obočí",
        text: "Dodejte svému obličeji výraz s precizní úpravou a barvením obočí, které zvýrazní vaši přirozenou krásu.",

    },
    {
        id: 6,
        title: "Péče v pohodlí vašeho domova",
        text: "Užijte si relaxaci a krásu přímo ve vašem vlastním prostoru! Naše služby vám přinášejí profesionální péči bez nutnosti opustit domov.",

    },
]

export default InfoData