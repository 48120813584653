import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import { Outlet } from "react-router-dom"
import CookiesConsent from "../components/CookiesConsent"


const SharedLayout = () => {
  return <>
    <Navbar />  
      <CookiesConsent />
    <Outlet />
    <Footer />
  </>
}

export default SharedLayout