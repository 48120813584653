 import "./Navbar.css"
 import {NavLink, Link} from "react-router-dom"
 import { GiHamburgerMenu } from "react-icons/gi";
 import { ImCross } from "react-icons/im";
 import logo from "../Images/BeautySalonNews.png"
 import { useState,useEffect } from "react";
 
 const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false)
  const [scrolled, setScrolled] = useState(false);

  // Sledování srolování
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header>
      <div className={`navbar ${scrolled ? "scrolled" : ""}`}>
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="logo-beauty" />
          </Link>
          <div className="navbar-text">
            <h3>BEAUTY RYCHNOV</h3>
            <p>VŠE PRO VAŠI KRÁSU</p>
          </div>
          <button onClick={() => setShowMenu(!showMenu)}>
            {showMenu ? (
              <ImCross className="cross-icon" />
            ) : (
              <GiHamburgerMenu className="hamburger-icon" />
            )}
          </button>
        </div>

        <div className={`${showMenu ? "show" : "hide"}`}>
          <nav>
            <NavLink to="/" onClick={() => setShowMenu(false)}>
              Domů
            </NavLink>
            <br />
            <NavLink to="/cenik" onClick={() => setShowMenu(false)}>
              Ceník
            </NavLink>
            <br />
            <NavLink to="/lrbeuty" onClick={() => setShowMenu(false)}>
              LR
            </NavLink>
            <br />
            <NavLink to="/kontakt" onClick={() => setShowMenu(false)}>
              Kontakt
            </NavLink>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Navbar;