import {BrowserRouter, Routes, Route} from "react-router-dom"
import Home from "./Pages/Home"
import Cenik from "./Pages/Cenik"
import Kontakt from "./Pages/Kontakt"
import Lrbeuty from "./Pages/LrBeuty"
import SharedLayout from "./Pages/SharedLayout"
import ScrollToTop from "./Pages/ScrollToTop"
import CookiesPage from "./Pages/Cookies"


const App = () => {
  return <BrowserRouter>
      <ScrollToTop />
      <Routes>
          <Route path="/" element={<SharedLayout />}>
          <Route index path="/" element={<Home />} />
          <Route path="cenik" element={<Cenik />}/>
          <Route path="lrbeuty" element={<Lrbeuty />}/>
          <Route path="kontakt" element={<Kontakt />} />
          <Route path="CookiesPage" element={ <CookiesPage />} />
        </Route>
      </Routes>
  </BrowserRouter>
}

export default App