import "./LrBeuty.css"
import LrBeautyData from "../components/data/LrBeautyData"
import { Link } from "react-router-dom"


const LrBeuty = () => {


  return <section id="all-articles">
    <div className="text">
      <h1>LR Beauty</h1>
    <p>
    Objevte LR Beauty, kosmetiku, která spojuje přírodní ingredience s inovativními recepturami pro zdravou a krásnou pleť. Díky široké nabídce produktů a důrazu na udržitelnost nabízí efektivní a bezpečná řešení v péči o pleť. <strong>10 důvodů, proč používat LR Beauty:</strong>      </p>
    </div>
    {LrBeautyData.map((res) => {
      const {id, title, text, number} = res
      return <div key={id} className="article">
        <h2>{number}</h2>
        <h1>{title}</h1>
        <p>{text}</p>
        </div>
    })}
    <div className="nadpis"><h1>Navštivte e-shop</h1></div>
    <div className="registration">
      <div className="registration-1-2">
          <h1>Registrace 30% sleva</h1>
          <p>Registrací u LR získáte 30% slevu na celý sortiment, kterou aktivujete při prvním nákupu nad 2000 Kč. Pokud tento minimální limit nesplníte, registrace nebude uplatněna. Jakmile ale nakoupíte za více než 2000 Kč, sleva vám zůstane trvale pro všechny další nákupy a můžete ji kombinovat s ostatními slevami. Taktéž získáte přístup k exkluzivním nabídkám a novinkám.</p>
          <div className="link">
            <Link to="https://partner.lr-czech.com/LrObjednavka.aspx?stat=CZ&sponzor=50102819_Hana_%C5%A0imkov%C3%A1_Petr_Pecina">Registrovat</Link>
          </div>
      </div>
      <div className="registration-1-2">
          <h1>Bez registrace</h1>
          <p>Bez registrace u LR přicházíte o možnost trvalé 30% slevy na všechny produkty a exkluzivní výhody, které registrovaní zákazníci získávají. Sleva zůstává dostupná i bez registrace, ale není tak výhodná a nelze ji kombinovat s dalšími akcemi.</p>
          <div className="link">
            <Link to="https://www.lr-czech.com/?sponzor=50102819_Hana_%C5%A0imkov%C3%A1_Petr_Pecina">Pokračovat bez registrace</Link>
          </div>
      </div>

    </div>
  </section>
}

export default LrBeuty