


const LrBeautyData = [
    {
        id: 1,
        number: "1.",
        title: "PŘÍRODNÍ INGREDIENCE" ,
        text: "Produkty LR Beauty obsahují přírodní složky, které jsou šetrné k pleti a pomáhají zachovat její přirozenou krásu bez použití agresivních chemikálií.",
    },
    {
        id: 2,
        number: "2.",
        title: "VĚDECKY OVĚŘENÉ" ,
        text: "LR Beauty se opírá o moderní vědecké výzkumy a inovativní technologie, což zajišťuje efektivitu a kvalitu výrobků.",
    },
    {
        id: 3,
        number: "3.",
        title: "HYDRATACE PLETI" ,
        text: "Produkty LR Beauty jsou navrženy tak, aby intenzivně hydratovaly pleť, což pomáhá zlepšit její texturu a vzhled.",
    },
    {
        id: 4,
        number: "4.",
        title: "ANTIOXIDAČNÍ OCHRANA" ,
        text: "Obsahují antioxidanty, které chrání pleť před poškozením volnými radikály, čímž zpomalují proces stárnutí.",
    },
    {
        id: 5,
        number: "5.",
        title: "ŠIROKÁ ŠKÁLA PRODUKTŮ" ,
        text: "LR Beauty nabízí široký výběr výrobků, které vyhovují různým potřebám pleti, včetně přípravků na akné, stárnutí pleti a citlivou pleť.",
    },
    {
        id: 6,
        number: "6.",
        title: "DOSTUPNOST" ,
        text: "Produkty LR Beauty jsou snadno dostupné, ať už online nebo prostřednictvím lokálních prodejců, což usnadňuje jejich pořízení.",
    },
    {
        id: 7,
        number: "7.",
        title: "EKOLOGICKÁ ODPOVĚDNOST" ,
        text: "LR Beauty se zavazuje k ekologické udržitelnosti, což znamená, že jejich výrobky jsou vyráběny s ohledem na životní prostředí.",
    },
    {
        id: 8,
        number: "8.",
        title: "KLINICKY TESTOVÁNO" ,
        text: "Všechny produkty jsou klinicky testovány a dermatologicky ověřeny, což zaručuje jejich bezpečnost a účinnost.",
    },
    {
        id: 9,
        number: "9.",
        title: "OSOBNÍ PÉČE" ,
        text: "LR Beauty nabízí možnost individuální péče, díky čemuž si každý může vybrat produkty přesně podle svých potřeb a preferencí.",
    },
    {
        id: 10,
        number: "10.",
        title: "ZKUŠENOSTI ODBORNÍKŮ" ,
        text: "Produkty jsou vyvíjeny ve spolupráci s odborníky na kosmetiku a dermatology, což zajišťuje, že splňují nejvyšší standardy kvality a účinnosti.",
    },


]

export default LrBeautyData


