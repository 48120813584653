import SliderData from './data/SliderData'
import { useEffect, useState } from 'react'
import "./Slider.css"

const Slider = () => {
    const [index, setIndex] = useState(0)
    
    useEffect( () => { 
        const intervalID = setInterval(() => {
            setIndex(prevIndex => (prevIndex + 1) % SliderData.length) // Opraveno 'length'
        }, 3000)
        return () => clearInterval(intervalID)
    },[])

    const currentItem = SliderData[index]
    
    return (
        <div className='all-items'>
            {/* Ujistěte se, že currentItem je objekt, ne pole */}
            <div key={currentItem.id} className="current-item">
                <div className="background-image">
                    <img src={currentItem.image} alt="background" />
                </div>
                <div className="text-item">
                    <h3>{currentItem.subtitle}</h3>
                    <h2>{currentItem.title}</h2>
                    <p>{currentItem.text}</p>
                </div>
            </div>
        </div>
    )
}

export default Slider