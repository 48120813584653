import React from 'react';
import priceData from '../components/data/PriceData';
import "./Cenik.css";

function ServicesList() {
  return (
    <section id="sluzby">
      <div className='special-offers'>
        <h2>Speciální nabídka pro nové zákazníky! </h2>
        <p>Získejte <strong>10% slevu</strong> na všechny naše služby!</p>
      
        <h2>Pro naše věrné zákazníky:</h2>
        <p>Na každou <strong>5. návštěvu</strong> máte <strong>50% slevu</strong>!</p>
      
        <h2>Nezapomeňte!</h2>
        <p>Nabízíme možnost služeb přímo <strong>u vás doma</strong>! Užívejte si luxus a pohodlí přímo ve svém obýváku!</p>
      </div>
      {/* Sekce pro ženy */}
      <div className='nadpis cara'> 
        <h1>Ženy</h1>
        <h2>Výhodné balíčky</h2>
      </div>
      <div className='balicky'>
        {priceData.basicPackages.map((res) => {
          const { id, name, services, price } = res;
          return (
            <div key={id} className='article'>
              <h2>{name}</h2>
              <ul>
                {services.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
              <h3>{price} Kč</h3>
            </div>
          );
        })}
      </div>
      <div className='nadpis'>
        <h2>Barvení</h2>
      </div>
      <div className='barveni'>
        {priceData.additionalServices.map((res, index) => {
          const { name, description, price } = res;
          return (
            <div key={index} className='barveni-article'>
              <h2>{name}</h2>
              <p>{description}</p>
              <h3>{price} Kč</h3>
            </div>
          );
        })}
      </div>
      <div className='nadpis '>
        <h2>Depilace</h2>
      </div>
      <div className='depilace'>
        {priceData.depilace.map((res, index) => {
          const { name, subServices } = res;
          return (
            <div key={index} className='depilace-article'>
              <h2>{name}</h2>
              {subServices.map((subRes, subIndex) => {
                const { area, price } = subRes;
                return (
                  <div key={subIndex} className='depilace-description'>
                    <p>{area}</p>
                    <h3>{price} Kč</h3>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>

      {/* Sekce pro muže */}
      <div className='nadpis cara'> 
        <h1>Muži</h1>
        <h2>Výhodné balíčky</h2>
      </div>
      <div className='balicky'>
        {priceData.MenPackages.map((res) => {
          const { id, name, services, price } = res;
          return (
            <div key={id} className='article'>
              <h2>{name}</h2>
              <ul>
                {services.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
              <h3>{price} Kč</h3>
            </div>
          );
        })}
      </div>
      <div className='nadpis'>
        <h2>Depilace</h2>
      </div>
      <div className='depilace'>
        {priceData.MenServices.map((res, index) => {
          const { name, subServices } = res;
          return (
            <div key={index} className='depilace-article'>
              <h2>{name}</h2>
              {subServices.map((subRes, subIndex) => {
                const { area, price } = subRes;
                return (
                  <div key={subIndex} className='depilace-description'>
                    <p>{area}</p>
                    <h3>{price} Kč</h3>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </section>
  )
}

export default ServicesList;