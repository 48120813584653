import { useState,useEffect } from "react"
import Cookies from "js-cookie"
import ReactGA from "react-ga"
import {Link} from "react-router-dom"
import "./CookiesConsent.css"

const CookiesConsent = () => {
  const [cookieAccepted, setCookieAccepted] = useState(false)
  const [showBanner, setShowBanner] = useState(true)

  useEffect(() => {
    const accepted = Cookies.get("cookieConsent")
    if (accepted) {
      setCookieAccepted(true)
      ReactGA.initialize("G-28ML9E748R")
      trackPage(window.location.pathname)
      setTimeout(() => {
        setShowBanner(false)
      }, 3000)
    }
  }, [])

  const handleAcceptCookies = () => {
    Cookies.set("cookieConsent", "true", {
      expires: 7, // Cookie vyprší za 7 dní
      secure: true, // Cookie bude bezpečné
      sameSite: "Strict", // Omezíme sdílení cookies
    })
    setCookieAccepted(true)
    trackPage(window.location.pathname)
    setTimeout(() => {
      setShowBanner(false)
    }, 3000)
  }
  const handleRemoveCookies = () => {
    Cookies.remove("cookieConsent")
    setCookieAccepted(false)
    setShowBanner(true)
  }

  const trackPage = (page) => {
    ReactGA.set({page})
    ReactGA.pageview(page)
  }

  if (!showBanner) return null

  return <section>
  {!cookieAccepted && (
      <div className="cookies-eu-banner">
          <p>
              Používáme cookies pro zlepšení vaší zkušenosti na našich stránkách. 
              Pokud souhlasíte s jejich používáním, klikněte na tlačítko níže.
          </p>
          <Link to={"/CookiesPage"}>Více informací</Link>
          <button onClick={handleAcceptCookies}>Souhlasím</button>
          <button onClick={handleRemoveCookies}>Odmítnout</button>
      </div>
  )}

  {cookieAccepted && (
      <div className="cookies-eu-banner">
          <p>Cookies byly již povoleny. Děkujeme!</p>
          <Link to={"/CookiesPage"}>Více informací</Link>
          <button onClick={handleRemoveCookies}>Odmítnout</button>
      </div>
  )}
</section>
}

export default CookiesConsent