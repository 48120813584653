const priceData = {
    basicPackages: [
      {
        id: 1,
        name: "BASIC",
        services: [
          "Masáž obličeje a dekoltu",
          "Hloubkové čištění pleti",
          "Peeling",
          "Sérum",
          "Krémy"
        ],
        price: 890
      },
      {
        id: 2,
        name: "COMFORT",
        services: [
          "Masáž obličeje a dekoltu",
          "Hloubkové čištění pleti",
          "Peeling",
          "Sérum",
          "Krémy",
          "Úprava a Barvení obočí",
          "Šetrná depilace obličeje"
        ],
        price: 1050
      },
      {
        id: 3,
        name: "EXCLUSIVE",
        services: [
          "Masáž obličeje",
          "Hloubkové čištění pleti",
          "Peeling",
          "Sérum",
          "Krémy",
          "Úprava a Barvení obočí",
          "Lifting obličeje a krku"
        ],
        price: 1250
      }
    ],
    additionalServices: [
      {
        name: "Obočí",
        description: "Barvení a úprava obočí",
        price: 150
      },
      {
        name: "Řas",
        description: "Barvení a úprava",
        price: 100
      },
    ],
    depilace: [
        {
            name: "Šetrná depilace",
            subServices: [
              { area: "Brada a horní ret", price: 70 },
              { area: "Líce", price: 100 },
              { area: "Ruce po lokty", price: 270 },
              { area: "Celé ruce", price: 380 },
              { area: "Podpaží", price: 250 },
              { area: "Lýtka", price: 350 },
              { area: "Stehna", price: 350 },
              { area: "Celé nohy", price: 550 }
            ]
          }
    ],
    MenPackages: [
        {
          id: 4,
          name: "BASIC",
          services: [
            "Masáž obličeje",
            "Hluboké čištění pleti",
            "Peeling",
            "Sérum",
            "Krémy"
          ],
          price: 690
        },
        {
          id: 5,
          name: "COMFORT",
          services: [
            "Masáž obličeje",
            "Hluboké čištění pleti",
            "Peeling",
            "Sérum",
            "Krémy",
            "Úprava vousů"
          ],
          price: 790
        },
        {
          id: 6,
          name: "EXCLUSIVE",
          services: [
            "Masáž obličeje",
            "Hluboké čištění pleti",
            "Peeling",
            "Sérum",
            "Krémy",
            "Úprava vousů",
            "Šetrná depilace obličeje"
          ],
          price: 890
        }
      ],
      MenServices: [
        {
          id: 1,
          name: "Šetrná depilace",
          subServices: [
            { area: "Hrudník", price: 270 },
            { area: "Břicho", price: 270 },
            { area: "Ruce po lokty", price: 300 },
            { area: "Celé Ruce", price: 450 },
            { area: "Záda", price: 370 },
            { area: "Lýtka", price: 450 },
            { area: "Stehna", price: 350 },
            { area: "Podpaží", price: 250 }
          ]
        }
      ]
    };

  
  export default priceData;