import image1 from "../../Images/slideruvod/masaz-obliceje.jpg"
import image2 from "../../Images/slideruvod/cisteni-ultrazvukem.jpg"
import image3 from "../../Images/slideruvod/poradenstvi.jpg"

const SliderData = [
    {
        id: 1,
        image: image1,
        title: "Masáž obličeje a dekoltu",
        subtitle: "BEAUTY RYCHNOV",
        text: "Uvolňuje napětí a zvyšuje průtok krve v tkáních. S jemnou masáží se podpoří lymfatický systém ke snažšímu vylučování toxinů.",

    },
    {
        id: 2,
        image: image2,
        title: "Ultrazvukové čištění",
        subtitle: "BEAUTY RYCHNOV",
        text: "Ultrazvukové čištění obličeje využívá vysokofrekvenční zvukové vibrace pro bezpečné hloubkové čištění pleti.",

    },
    {
        id: 3,
        image: image3,
        title: "Poradenství",
        subtitle: "BEAUTY RYCHNOV",
        text: "Poradenství je zaměřeno na doporučení kosmetiky přesně pro potřebu vaší pleti a životního stylu.",

    },
]

export default SliderData