import React from 'react'
import Slider from '../components/Slider'
import uvodImage from "../Images/uvod-picture.jpg"
import InfoData from '../components/data/InfoData'
import uvodImage2 from "../Images/hloubkove-cisteni.png"
import lr from "../Images/lr_global_holding_gmbh_cover.jpg"
import "./Home.css"
import {Link} from "react-router-dom"



const Home = () => {

  return <main>
    <div className='slider'>
      <Slider />
    </div>
    <div className='uvod-popis'>    
      <div className='main-article white'>
        <h2>Profesionální kosmetické služby s důrazem na vaši jedinečnost</h2>
        <p>
          V BEAUTY RYCHNOV se věnuji individuální péči o každého klienta. Mým cílem je nabídnout profesionální kosmetické služby, které zlepšují vzhled a zdraví pleti a posilují vaši sebedůvěru.
        </p>
      </div>
      <div className='main-article'>
        
        <img src={uvodImage} alt="uvodni-foto" />
      </div>
    </div>
    <div className='uvod-popis'> 
      <div className='main-article'>
      <img src={uvodImage2} alt="uvodni-foto" />

      </div>
      <div className='main-article white'>
        
      <h2>Přizpůsobené procedury pro vaši dokonalou relaxaci</h2>
      <p>
          Každá procedura, včetně masáží obličeje a hloubkového čištění pleti, je přizpůsobena vašim jedinečným potřebám. Vytvářím příjemné prostředí, kde se můžete plně soustředit na relaxaci a péči o sebe.
        </p>
      </div>
    </div>
    

    
    <div className='uvod-popis'>
    <div className='nadpis'><h1>DOPŘEJTE SI TU NEJLEPŠÍ PÉČI</h1></div>
      <div className='info-line'>
      {InfoData.filter(item => item.id === 1 || item.id === 2 || item.id === 3).map(item => (
    <div key={item.id} className='line-items white'>
        <h2>{item.title}</h2>
        <p>{item.text}</p>
    </div>
))}
        
        {InfoData.filter(item => item.id === 3 || item.id === 4 || item.id === 6).map(item => (
    <div key={item.id} className='line-items white'>
        <h2>{item.title}</h2>
        <p>{item.text}</p>
    </div>
))}
  </div>
        
  <div className='order-button'><Link to="/kontakt" >OBJEDNAT SE</Link></div>
  </div>
  <div className='nadpis'><h1>LR BEAUTY: CESTA K DOKONALÉ PLETI</h1></div>

<div className='uvod-popis'>
    <div className="main-article lr-beuty">
      <h2>Objevte Sílu Přírodní Kosmetiky s LR Beauty</h2>
      <p>
      Objevte sílu kosmetiky LR Beauty, která spojuje kvalitní přírodní ingredience s inovativními recepturami pro efektivní péči o pleť. LR Beauty nabízí širokou škálu produktů, dermatologická testování a osobní poradenství, které zajišťují zdravou a krásnou pleť. Důraz na udržitelnost a komplexní přístup přinášejí nejen krásný vzhled, ale i pohodu vaší pleti. Uživatelé zaznamenávají viditelné výsledky a spokojenost, což činí LR Beauty ideální volbou pro ty, kteří hledají efektivní a bezpečné řešení v péči o pleť.
      </p>
     </div>
      <div className="main-article">
        <img src={lr} alt='lr-beuty' />
      </div>
      
  </div>
  <div className='order-button center'><Link to="/LrBeuty" >DESET DŮVODŮ, PROČ ZVOLIT LR BEAUTY</Link></div>

    
  </main>
}

export default Home